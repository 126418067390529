<template>
  <div>
    <div>
      <heads :more="showMore" :msg="title_msg"></heads>
      <!-- <div class="binds">
        <p class="curre">当前绑定手机号</p>
        <p class="numbers">{{ current_mobile.substr(0, 3) + '****' + current_mobile.substr(7, 11) }}</p>
      </div> -->
      <div class="num_input">
        <div class="num_input_top">
          <p class="qh">+86</p>
          <span class="fg">|</span>
          <input v-model="phone" class="ipt" type="number" placeholder="请输入手机号">
        </div>
        <div>
          <input
            v-model="yzm"
            class="ipt"
            maxlength="6"
            type="text"
            placeholder="请输入验证码"
            @input="changes"
          >
          <span class="fgs">|</span>
          <p class="yzm" @click="getcode()">获取验证码</p>
        </div>
      </div>
      <van-button
        type="danger"
        style="width: 80%; margin: 20px auto; background: #ff272c"
        block
        :disabled="isdisabled"
        @click="sign_in()"
      >确认绑定</van-button>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import Cookie from 'js-cookie'
import { get_verification_CodeZXM } from '@/api/my_user.js'
import { signIn, sendMobileCode, bindMobile } from '@/api/login.js'
import { Toast } from 'vant'
export default {
  components: {
    heads

  },
  data() {
    return {
      yzm: '',
      uuid: this.$route.query.uuid,
      access_token: this.$route.query.access_token,
      isdisabled: true,
      title_msg: '手机绑定',
      phone: '',
      msg: '',
      isshow: false,
      current_mobile: '',
      showMore: true,
      ticket: '',
      randstr: ''
    }
  },
  mounted() {
    console.log(this.$route.query)
  },
  methods: {
    // 登录
    sign_in() {
      if (!this.phone) {
        Toast('请输入手机号')
        return
      }
      var params = {
        uuid: this.uuid,
        mobile: this.phone,
        code: this.yzm,
        access_token: this.access_token
      }
      bindMobile(params).then(res => {
        console.log(res)
        if (res.errNo !== 0) {
          Toast(res.msg)
        } else {
          window.location.href = this.redirect && this.redirect != undefined ? this.redirect : window.location.origin
          // this.$router.push({ path: '/mine' })
        }
      })
      // var params = {
      //   account_name: this.phone,
      //   username: this.$route.query.account_name,
      //   code: this.yzm,
      //   type: 1
      // }
      // signIn(params).then((res) => {
      //   console.log(res)
      //   if (this.popup_id && res.errNo === 0) {
      //     window.location.href = `http://openwww.zhulong.com/interview/#/hrresume?id=${this.popup_id}`
      //     return false
      //   }
      //   if (res.errNo !== 0) {
      //     Toast(res.msg)
      //   } else {
      //     window.location.href = this.redirect && this.redirect != undefined ? this.redirect : 'https://m.zhulong.com'
      //     // this.$router.push({ path: '/mine' })
      //   }
      //   // if (res.result) {
      //   //   this.$router.push({ path: '/mine' })
      //   // }
      // })
    },
    // 监听验证码值发生变化
    changes() {
      if (this.yzm) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    // 滑动验证
    getcode() {
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (!reg.test(this.phone)) {
        Toast('手机号格式有误')
        return false
      }
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        console.log(res)
        console.log(res.ret)
        if (res.ret == 0) {
          console.log(111)
          // 成功，传递数据给后台进行验证
          // that.iscode = false
          // that.againcode = true
          that.randstr = res.randstr
          that.ticket = res.ticket
          // that.gettime()
          //   that.sign_in()
          that.getyanzhengma()
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    down_() {
      this.timer = setInterval(() => {
        this.btnText--
        if (this.btnText == 1) {
          clearInterval(this.timer)
          this.timer = null
          this.btnText = '重发验证码'
        }
      }, 1000)
    },
    isshows() {
      if (this.password.length !== 0 && this.num.length !== 0) {
        this.isdisabled = true
      }
    },
    encrypt(msg) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrWVnSOu9m7O8X3taQGXzVlB9B0Gw1Mvbc0MxKZOxT8SlQVB1Krpu3KfuoxgGE1TikX/JkYJf+D4tTqENqk5pnSZc784gWZPEs2O+uz5R/8Ay8qP06uHDzw1oGDrpo8wxWQ7Ae2IwE2gTDtpcyg8NUJp14uYwsvA47iDpXHGmPxQIDAQAB-----END PUBLIC KEY-----'
      )
      return encrypt.encrypt(msg)
    },
    // 账号登陆与   &&  验证码登录切换/user/logon/signIn
    account_login(type) {
      this.type = type
      if (type === 1) {
        this.tabs1 = true
        this.tabs = false
        this.code_login = true
        this.numer_login = false
        this.isShowBtn = false
      } else {
        this.tabs = true
        this.tabs1 = false
        this.numer_login = true
        this.code_login = false
        this.isShowBtn = true
      }
      // this.active = !this.active
    },
    // 获取验证码
    getyanzhengma() {
      const params = {
        mobile: this.phone,
        code_type: 2
      }
      sendMobileCode(params).then(res => {
        if (res.errNo === 0) {
          this.btnText = 59
          this.down_()
          Toast('验证码发送成功')
        } else {
          Toast(res.msg)
        }
      })
      // const params = {
      //   mobile: this.phone,
      //   agency_id: Cookie.get('agency_id'),
      //   ticket: this.ticket,
      //   randstr: this.randstr,
      //   app_id: Cookie.get('app_id')
      // }
      // get_verification_CodeZXM(params).then((res) => {
      //   console.log(res)
      //   if (res.errNo == 0) {
      //     Toast('验证码发送成功')
      //   }
      // })
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .mores{
    display: none;
}
/deep/ .go_back{
    display: none;
}
.binds {
  text-align: center;
  line-height: 60px;
}
.curre {
  padding-top: 30px;
  color: #969696;
}
.numbers {
  letter-spacing: 4px;
}
.num_input {
  width: 680px;
  height: 185px;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin: 20px auto;
  background: #fafafa;
}
.num_input_top {
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #ededed;
}
.qh {
  line-height: 90px;
  padding: 0 55px 0 55px;
  float: left;
  color: #484848;
}
.fg {
  color: #ebebeb;
  line-height: 90px;
  float: left;
}

.ipt {
  border: none;
  float: left;
  line-height: 90px;
  margin-left: 40px;
  caret-color: red;
  color: #484848;
  background: #fafafa;
}
.yzm {
  color: #cc1939;
  font-size: 25px;
  line-height: 90px;
  float: right;
  padding-right: 20px;
}
.fgs {
  color: #ebebeb;
  line-height: 90px;
  float: left;
  padding-left: 150px;
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
</style>
